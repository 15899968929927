import React from 'react';

import G1 from './Image/G1.jpg'
import G2 from './Image/G2.jpg'
import G3 from './Image/G3.jpg'
import G4 from './Image/G4.jpg'

import G5  from './Image/G5.jpg'
import G6 from './Image/G6.jpg'

import G7 from './Image/G7.jpg'
import G8 from './Image/G8.jpg'
import G9  from './Image/G9.jpg'
import G10 from './Image/G10.jpg'

import G11 from './Image/G11.jpg'
import G12 from './Image/G12.jpg'
import G13  from './Image/G13.jpg'
import G14 from './Image/G14.jpg'


const images = [G1, G2, G3, G4, G5, G6, G7, G8, G9, G10, G11, G12, G13, G14,]

const SectionGallery = () => {
  return (
    <section className='text-center justify-center items-center mx-auto m-2 md:my-10'>
      <div className=' justify-center items-center m-2 mt-8 gap-5'>
        <h1 className='text-4xl mb-6 font-bold text-[#3b0067]'>Gallery</h1>
        <div className='flex flex-wrap gap-5 justify-center'>
          {images.map((image, index) => (
            <div className='md:w-1/4 w-full'>
            <div key={index}>
              <img src={image} alt={`Image ${index + 1}`}  className='w-full h-auto '/>
            </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionGallery;