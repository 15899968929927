import React, { useState } from 'react';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Assests/Logo/logo192.png';

const Header = () => {
  const Menu = [
    { name: "About Us", link: "/About" },
    // {name: 'News & Updates', link:'/News-Updates'},
    { name: "Toofani Squad", link: "/Squad" },
    { name: "Auction", link: "/Auction" },
    { name: "Fixtures & Results", link: "/Fixtures&Results" },
    { name: "Gallery", link: "/Gallery" },
    // { name: 'Sponsors', link: '/Sponsors'}
  ];

  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="relative shadow-md w-full top-0 left-0 bg-[#3b0067] py-5">
      <div className="md:flex max-w-[1300px] mx-auto items-center justify-between md:px-10 px-7">
        {/* logo section */}
        <div className="font-bold text-2xl cursor-pointer flex items-center gap-1">
          <Link to={'/'}><img src={logo} alt="Delhi Toofans" className='absolute z-10 h-full top-4 '/></Link>
        </div>
        {/* Menu icon */}
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-4 md:top-6 cursor-pointer md:hidden w-7 h-7 text-white"
        >
          {open ? <XMarkIcon /> : <Bars3BottomRightIcon />}
        </div>
        {/* link items */}
        <ul
          className={`md:flex md:items-center md:pb-0 pb-2 absolute md:static ms:bg-white bg-white md:bg-transparent  md:z-auto z-30 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-[3.75rem]" : "top-[-490px]"
          }`}
        >
          {Menu.map((link) => (
            <li key={link.name} className="md:ml-8 sm:ml-4 md:my-0 my-2 font-semibold">
              <Link
                to={link.link}
                className={`md:text-white text-black duration-500 ${
                  location.pathname === link.link ? 'border-b-2 border-blue-400' : ''
                }`}
                onClick={() => setOpen(false)}  // Close menu on link click
              >
                {link.name}
              </Link>
            </li>
          ))}
          <button className="btn bg-[#ffbd00] hover:bg-black text-black hover:text-[#ffbd00] border-white rounded-lg md:ml-8 font-semibold px-4 py-1 duration-500 md:static">
            <Link to={'/Contact'} onClick={() => setOpen(false)}> Contact Us</Link>
          </button>
        </ul>
      </div>
    </div>
  );
};

export default Header;
