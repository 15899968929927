import React from 'react'
import SliderShow from './SliderShow'
import OurDugout from './OurDugout'
import Sponsors from '../Sponsors'
import AboutUsImage from './AboutUsImage'

const AboutUs = () => {
  return (
    <>
    <SliderShow/>
    <AboutUsImage/>
    <OurDugout/>
    {/* <Sponsors/> */}
    </>
  )
}

export default AboutUs