import React, { useState } from 'react';

// Importing images for players and their hover states
// Attacker
import Amandeep from './Team/With BG/Amandeep Lakra.png';
import Arshdeep from './Team/With BG/Arshdeep Singh.png';
import Arthur from './Team/With BG/Arthur De Sloover.png';
import Bikramjit from './Team/With BG/Bikramjit Singh.png';
import Darshan from './Team/With BG/Darshan Vibhav Gawkar.png';
import Devindar from './Team/With BG/Devindar Sunil Walmiki.png';
import Irengbam from './Team/With BG/Irengbam Rohit.png';
import Jean from './Team/With BG/Jean Paul Danneberg.png';
import Maico from './Team/With BG/Maico Casella.png';
import Mukul from './Team/With BG/Mukul Sharma.png';
import Rahim from './Team/With BG/Rahim Aakib Sayyed.png';
import Rajinder from './Team/With BG/Rajinder singh.png';
import Sundaram from './Team/With BG/Sundaram Rajawat.png';
import Talwinder from './Team/With BG/Talwinder Singh.png';
import Vikash from './Team/With BG/Vikas Dahiya.png';
import akshay from './Team/With BG/akshay-avhad.png';
import gonzalo from './Team/With BG/gonzalo peillat.png';
import matthew from './Team/With BG/matthew dawson.png';
import nilakanta from './Team/With BG/nilakanta sharma.png';
import shilan from './Team/With BG/shilanand lakra.png';
import sumit from './Team/With BG/sumit walmiki.png';
import terrance from './Team/With BG/terrance pieters.png';
import timothy from './Team/With BG/timothy daniel brand.png';
import zachary from './Team/With BG/zachary wallace.png';

// Hover Images
import AmandeepHover from './Team/Without BG/Amandeep Lakra_01.png';
import ArshdeepHover from './Team/Without BG/Arshdeep Singh_01.png';
import ArthurHover from './Team/Without BG/Arthur De Sloover_01.png';
import BikramjitHover from './Team/Without BG/Bikramjit Singh_01.png';
import DarshanHover from './Team/Without BG/Darshan Vibhav Gawkar_01.png';
import DevindarHover from './Team/Without BG/Devindar Sunil Walmiki_01.png';  
import IrengbamHover from './Team/Without BG/Irengbam Rohit_01.png';
import JeanHover from './Team/Without BG/Jean Paul Danneberg_01.png';
import MaicoHover from './Team/Without BG/Maico Casella_01.png';
import MukulHover from './Team/Without BG/Mukul Sharma_01.png';
import RahimHover from './Team/Without BG/Rahim Aakib Sayyed_01.png';
import RajinderHover from './Team/Without BG/Rajinder singh_01.png';
import SundaramHover from './Team/Without BG/Sundaram Rajawat_01.png';
import TalwinderHover from './Team/Without BG/Talwinder Singh_01.png';
import VikashHover from './Team/Without BG/Vikas Dahiya_01.png';
import akshayHover from './Team/Without BG/akshay-avhad_01.png';
import gonzaloHover from './Team/Without BG/gonzalo peillat_01.png';
import matthewHover from './Team/Without BG/matthew dawson_01.png';
import nilakantaHover from './Team/Without BG/nilakanta sharma_01.png';
import shilanHover from './Team/Without BG/shilanand lakra_01.png';
import sumitHover from './Team/Without BG/sumit walmiki_01.png';
import terranceHover from './Team/Without BG/terrance pieters_01.png';
import timothyHover from './Team/Without BG/timothy daniel brand_01.png';
import zacharyHover from './Team/Without BG/zachary wallace_01.png';


// Grid Item Component to display player info
const GridItem = ({ image, name, position, bio, hoverImage, onHover }) => {
  return (
    <div className='relative flex flex-col items-center md:w-1/5 w-full mb-2 gap-4 mx-1'>
      <img 
        src={image} 
        alt={name} 
        className='z-10' 
        onMouseEnter={() => onHover({ name, position, hoverImage, bio })}
        onMouseLeave={() => onHover(null)}
      />
      <span className='font-bold text-xl py-1 z-30'>{name}</span>
      <span className='font-semibold z-30'>{position}</span>
    </div>
  );
};

// Modal Box Component to display player hover image
const ModalBox = ({ player, isVisible, onClose }) => {
  if (!isVisible || !player) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white py-5 px-4 rounded-lg relative mx-auto">
        <button 
          className="absolute top-0 right-1 text-2xl font-bold cursor-pointer" 
          onClick={onClose}
        >
          &times;
        </button>
        <div className='md:w-96 w-auto h-auto mb-4 '>
        <p className='text-justify text-xl p-3 tracking-tight'>{player.bio}</p>
        {/* <img src={player.hoverImage} alt={player.name} className="w-auto md:h-96 h-auto mb-4" /> */}
        <h2 className="text-center font-bold text-xl mb-2">{player.name}</h2>
        <p className="text-center font-semibold">{player.position}</p>
        </div>
      </div>
    </div>
  );
};

// Main Section for the Toofani Squad
const SectionToofaniSquad = () => {
  const [hoveredPlayer, setHoveredPlayer] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);

  let enterTimeout, leaveTimeout;

  // Handle hover event to show modal with debounce to prevent flickering
  const handleHover = (player) => {
    if (leaveTimeout) clearTimeout(leaveTimeout);

    if (player) {
      enterTimeout = setTimeout(() => {
        setHoveredPlayer(player);
        setModalVisible(true);
        console.log('Player hovered:', player); // Debugging line
      }, 500);  // small delay to smooth out the hover transition
    } else {
      clearTimeout(enterTimeout);  // Prevent hover from triggering after mouse leaves
      leaveTimeout = setTimeout(() => {
        setModalVisible(false);
        console.log('Hover left, closing modal'); // Debugging line
      }, 20000);
    }
  };

  // Arrays of player data
  const Forward  = [
    { image: Arshdeep, name: 'Arshdeep Singh', position: 'Forward', hoverImage: ArshdeepHover, bio:"Possibly the most exciting striker in the junior programme right now, Arshdeep could have a breakthrough season with the Toofans. Really fast and skilful with the ability to turn on a six pence, Arshdeep has the ability to open up a defence with his individual brilliance as well as score a multitude of goals. Arshdeep is a player we think will absolutely deliver and could be spearheading the attack for the Toofans in the years to come."  },
    { image: Irengbam, name: 'Irengbam Rohit', position: 'Forward', hoverImage: IrengbamHover, bio:"The youngest member in the team, Rohit is an exciting young forward with speed and skill and is also already a capable drag flicker. Originally from the north eastern State of Manipur, Rohit is a product of one of India’s top High Performance Centres located in Bhubaneswar, Odisha. With Rohit, the thinking is long term and we are happy to provide him a pathway that will help him excel and one day establish himself in the senior team. We are glad to have this exciting young talent on-board with us and we are happy to play a part in his development." },
    { image: Talwinder, name: 'Talwinder Singh', position: 'Forward', hoverImage: TalwinderHover, bio:"Talwinder Singh is a player with very high game intelligence and an expert at finding space inside the circle. Talwinder is having an excellent domestic campaign where he is scoring goals aplenty. Equally dangerous playing anywhere across the front three, Talwinder also brings with him immense amount of experience as he has played International hockey and represented India at the highest level. As he links up with world class talent upfront for the Toofans, we are expecting Talwinder to carry his domestic form into the Hockey India League and have a huge impact for the team." },
    { image: terrance, name: 'Terrance Pieters', position: 'Forward', hoverImage: terranceHover, bio:"Terrance grew up in the multicultural Dutch city of Almere as he went on to become the first player of colour to represent the Netherlands in field hockey. Terrance made his senior international debut with the Netherlands in 2017 and has since gone on to establish himself as an accomplished striker and goal poacher at Club and International level. He is sure to add extra quality up front for the Hyderabad Toofans and we are looking forward to Terrancec contributing immensely to our upcoming season on and off the pitch." },
    { image: timothy, name: 'Timothy Daniel', position: 'Forward', hoverImage: timothyHover, bio:"Tim Brand is one of the most explosive attacking players in world hockey. An extremely capable ball carrier, he can open up defences at will with his ability to accelerate into and attack open spaces. He is an Olympic silver medallist for Australia and along with being a top goal contributor, Tim is also known for his incredibly high work rate on the pitch. Despite missing the Gold at Tokyo, Tim ended his debut Olympic Games with 5 goals and one impressive performance after another. We are sure of him having a similar impact in his debut season at the Hockey India League for the Hyderabad Toofans." },
    { image: shilan, name: 'Shilanand Lakra', position: 'Forward', hoverImage: shilanHover, bio:"Shilanand Lakra possesses the dangerous combination of skill and speed which makes him such a difficult player to play against. Extremely deceptive when in possession of the ball, Shilanand can play across the front three but can also drop back into a creative role to help with the build up. Currently part of the national team core group probables, we believe this could be a breakout year for Shilanand Lakra where he has a deep impact on the Hockey India League while also cementing his position in attack for the national team. We are sure Shilanand will contribute immensely as the Hyderabad Toofans fight for glory." },

  ];

  const Midfielder  = [
    { image: Darshan, name: 'Darshan Vibhav Gawkar', position: 'Midfielder', hoverImage: DarshanHover, bio:"A regular as an attacking midfielder for India’s top domestic team, RSPB, Darshan was part of the junior programme before moving on to the senior core group for the last Asia Cup. Unfortunately he did not make the team then but has since gone from strength to strength winning medals at the National Games, National Championship and going on to medal at almost every major tournament in India. At 23, we believe his best years are ahead of him and his brand of adventurous and exciting hockey fits perfect with the kind of hockey we want to play." },
    { image: nilakanta, name: 'Nilakanta Sharma', position: 'Midfielder', hoverImage: nilakantaHover, bio:"Arguably the best box-to-box midfielder India has produced in the last seven or eight years. Rose through the ranks with Sumit and has since gone on to medal at the Olympics, Asian and Commonwealth Games. Nilakanta has a tremendous capacity to take on workload and we expect him to be as solid in midfield for the Toofans. Part of that brilliant Junior World Cup winning team of 2016, Nilakanta is now a multidimensional midfielder who contributes greatly to the overall output of the team in midfield." },
    { image: Maico, name: 'Maico Casella', position: 'Midfielder', hoverImage: MaicoHover, bio:"Maico Casella has gone on to represent his nation Argentina at two Olympic Games. Maico possesses the deadly combination of being a dangerous drag flicker and a natural goal scorer while contributing significantly in attacking transitions. We are sure Maico will be one of the most exciting players to watch out for in the Hockey India League as he gives us immense firepower in attack. At 27, Maico is currently at his peak and we are hoping his goals will fire the Toofans to glory in the very first season of the Hockey India League." },
    { image: Rahim, name: 'Rahim Aakib Sayyed', position: 'Midfielder', hoverImage: RahimHover, bio:"Aakib has been one of the most consistent performers in midfield in domestic hockey whether he is playing for his team the Indian Navy or his state Maharashtra. Capable of being a box-to-box midfielder or playing as a single pivot in a defensive role, Aakib excels in high pressure situations. His ability to adapt positionally and his extremely high fitness level are his biggest strengths. When it comes to work rate, Aakib is usually second to none and we believe he is currently at the peak of his hockey career. Aakib is an important addition to the Toofans midfield." },
    { image: Rajinder, name: 'Rajinder', position: 'Midfielder', hoverImage: RajinderHover, bio:"A dynamo in midfield, Rajinder was part of the Junior World Cup team in Kuala Lumpur last December. Since then, the sturdily built Rajinder has gone from strength to strength as he has elevated his game to become a part of the National Team Core group. An International debut seems a matter of time with the way he is playing currently. Equally adept at playing as a creative midfielder or a box-to-box role, Rajinder will bring amazing quality and energy to the Toofans midfield." },
    { image: sumit, name: 'Sumit', position: 'Midfielder', hoverImage: sumitHover, bio:"Double Olympic Medallist and a Jr World Cup winner, Sumit is a technically solid defender who is blessed with an amazing aerobic engine. Equally good on the right of defence as he is on the left, Sumit is an integral part of the national setup and we believe he will be as critical for the Toofans as we look to build a solid defensive base. Sumit contributes both in penalty corner attack and defence and will surely be one of the senior voices in the dressing room as he looks to inspire the junior players in the team to consistently perform at a very high level." },
    { image: zachary, name: 'Zachary Wallace', position: 'Midfielder', hoverImage: zacharyHover, bio:"Currently, an FIH World Player of the Year nominee, Zach is undoubtedly one of the most exciting players in world hockey. An absolute joy to watch with his creativity, vision and his ability to drive with the ball, Zach is more than a handful for any opposition defence. His unpredictability and his game intelligence is sure to make him a firm fan favourite and his ability to score field goals at critical times makes him a nightmare to play against. Zach could be a player who goes on to have a huge impact on how the league unfolds." },
    
  ];

  const Defender  = [
    { image: akshay, name: 'Akshay Ravindra Avhad', position: 'Defender', hoverImage: akshayHover,bio:"His game intelligence allows him to play across the back four as well as a defensive midfielder. Akshay is also a really good first runner and has been playing for his State Maharashtra at the National Games and the National Championship as well as playing for ONGC and now BPCL in domestic tournaments. He is currently approaching the peak of his career and his situational understanding and game reading is only getting better with every passing tournament. An excellent option in defence, Akshay is sure to add value to the overall defensive output of the team." },
    { image: Amandeep, name: 'Amandeep Lakra', position: 'Defender', hoverImage: AmandeepHover, bio:"Another member of the Junior World Cup squad, Amandeep is a quality drag flicker while being a dependable central defender. He is likely to play a key role in deep defence along with experienced campaigners Matthew Dawson and Gonzalo Peillat. We believe Amandeep is going to have a huge role to play in the back line for India in the years to come and we are extremely pleased to give him a platform to show the world how good he really is." },
    { image: Devindar, name: 'Devindar Sunil Walmiki', position: 'Defender', hoverImage: DevindarHover, bio:"A player capable of playing across the backline and in midfield, Devindar’s extreme adaptability and game intelligence makes him a player of extreme utility. Part of the Indian team that went to the Rio Olympics, Devindar has spent a number of seasons in the Hoofdklaase and just captained the PSPB team that went on to win the National Championship a few weeks ago. He is sure to play a critical role as the Toofans will go on to mount a title challenge. As he teams up with the likes of Zach Wallace and Nilakanta Sharma in midfield, he is sure to be a force to be reckoned with." },
    { image: Arthur, name: 'Arthur De Sloover', position: 'Defender', hoverImage: ArthurHover, bio:"Arthur De Sloover is a World, Olympic and European Champion with Belgium. Arthur can play multiple positions in defence and in midfield expertly, making him a player of extreme utility. An FIH Rising Star Award winner for the year 2018, Arthur is amongst the most consistent performers in world hockey for a while now. We look forward to the quality and leadership Arthur will bring for the Toofans in midfield and in defence as he helps the Toofans fight for the championship in the inaugural season." },
    { image: gonzalo, name: 'Gonzalo Peillat', position: 'Defender', hoverImage: gonzaloHover, bio:"Quite simply, one of the greatest drag flickers the world has ever seen! Gonzalo is a World and Olympic Champion and has no intentions of slowing down as he almost went on to win his second Olympic Gold Medal just a couple of months ago in Paris with Germany. He will be one of the senior voices in the dressing room and along with a bucket full of goals, Gonzalo is also sure to bring experience and leadership at the heart of our deep defence." },
    { image: matthew, name: 'Matthew Dawson', position: 'Defender', hoverImage: matthewHover, bio:"Matthew ‘Matt’ Dawson has been a mainstay in the Australian defence for close to a decade now. A player of unbelievable consistency, he has gone on to play for Australia at 3 Olympic Games winning silver in Tokyo as the team reached the finals for the first time since 2004. Playing alongside Gonzalo Peillat, Matt will give us amazing defensive solidity. It speaks volumes of his commitment that he had his finger amputated just days before the Paris Olympics after sustaining an injury in training because having undergo surgery would have meant missing out on the Olympics." },
    { image: Mukul, name: 'Mukul Sharma', position: 'Defender', hoverImage: MukulHover, bio:"Mukul plays for Haryana and the South Central Railway on the domestic circuit. Primarily a right defender, Mukul is a danger to any opposition with his ability to create overloads in the attacking third. Along with making those marauding runs down the right , Mukul is also a fine first rusher which is a critical skill to possess in modern hockey, With his ability to retackle and run back into defence, Mukul is sure to play a huge role in the inaugural season for the Hyderabad Toofans as they look to maintain the balance in attack and defence." },
    { image: Sundaram, name: 'Sundaram Rajawat', position: 'Defender', hoverImage: SundaramHover, bio:"Part of the junior core group and a product of the MP Hockey Academy, Sundaram has a maturity in his game that belies his age. Capable of playing on the right and left of defence and blessed with an amazing aerobic engine, Sundaram is as effective in defence as he is in attack. He is also comfortable playing as an inside defender. He has excellent anticipation which allows him to be at the right place at the right time and we believe he has an extremely bright future ahead of him." },
   
   
  ];

  const goalies = [
    { image: Bikramjit, name: 'Bikramjit Singh', position: 'Goalkeeper', hoverImage: BikramjitHover, bio:"The number one junior goalkeeper in the country right now, Bikramjeet is getting better with every passing month. An excellent shot stopper who is developing rapidly as a brilliant all round keeper, Bikramjeet is impressing one and all with one good performance after another. Currently in the junior programme under the guidance of PR Sreejesh, we believe it is only a matter of time until Bikramjeet makes the step up to the senior side." },
    { image: Jean, name: 'Jean Paul Danneberg', position: 'Goalkeeper', hoverImage: JeanHover, bio:"" },
    { image: Vikash, name: 'Vikas Dahiya', position: 'Goalkeeper', hoverImage: VikashHover, bio:"Vikas comes into the team with immense experience and big games under his belt. He went to the Rio Olympic Games as the travelling keeper and won the Junior World Cup in 2016. Apart from the Olympics, Vikas was also part of the Indian squads that competed at the 2016 Champions Trophy and the Hockey World League. As of this moment, Vikas plays in goal for one of the top teams on the domestic circuit, BPCL. We are sure Vikas will bring an added layer of quality and experience to our goalkeeping unit, and we wish him all the best." },
  ];

  return (
    <section className='mb-6'>
      <h1 className='py-4 bg-[#ffbd00] text-center text-4xl font-bold'>Toofani Squad</h1>
      <div className='max-auto md:px-[8rem]'>

        {/* Center Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Forward</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {Forward.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>

        {/* Right Wing Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Midfielder</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {Midfielder.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>

        {/* Deferder Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Defender</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {Defender.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>

        {/* Goalie Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Goalie</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {goalies.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>
      </div>

      {/* Modal for player hover */}
      <ModalBox 
        player={hoveredPlayer}
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </section>
  );
};

export default SectionToofaniSquad;
