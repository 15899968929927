import React from 'react'
import PlayersPrice from './PlayersPrice'

const Auction = () => {
  return (
    <>
    <section className='text-black py-4 mx-auto md:px-[10rem]'>
        <div className='flex flex-col md:flex-row justify-between mx-auto m-2 mt-6 md:px-[9rem] gap-4 border border-gray-300 py-5 rounded-lg '>
            <div className='text-center border-r border-gray-500 justify-center items-center flex-1 '>
             <h3 className='uppercase font-semibold'>Purse Amount (₹)</h3>
             <span className='text-2xl font-bold text-[#ffbd00]'>4.00 cr</span>
            </div>
            <div className='text-center border-r border-gray-500 justify-center items-center flex-1 '>
             <h3 className='uppercase font-semibold'>Total Spent (₹)</h3>
             <span className='text-2xl font-bold text-[#ffbd00]'>3.992 cr</span>
            </div>
            <div className='text-center justify-center items-center flex-1 '>
             <h3 className='uppercase font-semibold'>Remaining Amount (₹)</h3>
             <span className='text-2xl font-bold text-[#ffbd00]'>0.80 cr</span>
            </div>
        </div>
        <div className='flex flex-col md:flex-row justify-between mx-auto m-2 mt-8 md:px-[9rem] gap-4 border border-gray-300 py-5 rounded-lg'>
            <div className='text-center border-r border-gray-500 justify-center items-center flex-1'>
             <h3 className='uppercase font-semibold'>Forward</h3>
             <span className='text-xl font-bold text-[#ffbd00]'>6</span>
            </div>
            <div className='text-center border-r border-gray-500 justify-center items-center flex-1'>
             <h3 className='uppercase font-semibold'>Midfielder</h3>
             <span className='text-xl font-bold text-[#ffbd00]'>7</span>
            </div>
            <div className='text-center border-r border-gray-500 justify-center items-center flex-1'>
             <h3 className='uppercase font-semibold'>Defender</h3>
             <span className='text-xl font-bold text-[#ffbd00]'>8</span>
            </div>
            <div className='text-center border-r border-gray-500 justify-center items-center flex-1'>
             <h3 className='uppercase font-semibold'>Goalkeeper</h3>
             <span className='text-xl font-bold text-[#ffbd00]'>3</span>
            </div>
            <div className='text-center justify-center items-center flex-1'>
             <h3 className='uppercase font-semibold'>Avg team Age</h3>
             <span className='text-xl font-bold text-[#ffbd00]'>28</span> 
            </div>
        </div>
    </section>
    <PlayersPrice/>
    </>
  )
}

export default Auction