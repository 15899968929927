import React from 'react'
import ContactForm from './ContactForm'
import Sponsors from '../Sponsors'

const SectionContact = () => {
  return (
    <>
    <ContactForm/>
    {/* <Sponsors/> */}
    </>
  )
}

export default SectionContact