import React from 'react';
import { GiCommercialAirplane } from "react-icons/gi";

const PlayersPrice = () => {
  const playersData = [
    { name: "Gonzalo Peillat", basePrice: "10.00 L", auctionPrice: "68.00 L" },
    { name: "Matthew Dawson", basePrice: "10.00 L", auctionPrice: "10.00 L" },
    { name: "Nilakanta Sharma", basePrice: "10.00 L", auctionPrice: "34.00 L" },
    { name: "Shilanand Lakra", basePrice: "10.00 L", auctionPrice: "21.00 L" },
    { name: "Sumit", basePrice: "10.00 L", auctionPrice: "46.00 L" },
    { name: "Terrance Pieters", basePrice: "10.00 L", auctionPrice: "10.00 L" },
    { name: "Timothy Daniel", basePrice: "10.00 L", auctionPrice: "28.00 L" },
    { name: "Zachary Wallace", basePrice: "10.00 L", auctionPrice: "26.00 L" },
    { name: "Amandeep Lakra", basePrice: "05.00 L", auctionPrice: "09.00 L" },
    { name: "Arshdeep Singh", basePrice: "05.00 L", auctionPrice: "11.50 L" },
    { name: "Arthur De Sloover", basePrice: "10.00 L", auctionPrice: "30.00 L" },
    { name: "Bikramjit Singh", basePrice: "05.00 L", auctionPrice: "06.20 L" },
    { name: "Darshan Vibhav Gawkar", basePrice: "02.00 L", auctionPrice: "02.00 L" },
    { name: "Devindar Sunil Walmiki", basePrice: "05.00 L", auctionPrice: "08.00 L" },
    { name: "Irengbam Rohit", basePrice: "02.00 L", auctionPrice: "02.00 L" },
    { name: "Jean Paul Danneberg", basePrice: "10.00 L", auctionPrice: "27.00 L" },
    { name: "Maico Casella", basePrice: "10.00 L", auctionPrice: "10.00 L" },
    { name: "Mukul Sharma", basePrice: "02.00 L", auctionPrice: "02.00 L" },
    { name: "Rahim Aakib Sayyed", basePrice: "02.00 L", auctionPrice: "23.00 L" },
    { name: "Rajinder", basePrice: "02.00 L", auctionPrice: "02.00 L" },
    { name: "Sundaram Rajawat", basePrice: "05.00 L", auctionPrice: "14.50 L" },
    { name: "Talwinder Singh", basePrice: "02.00 L", auctionPrice: "02.00 L" },
    { name: "Vikas Dahiya", basePrice: "05.00 L", auctionPrice: "05.00 L" },
    { name: "Akshay Ravindra Avhad", basePrice: "05.00 L", auctionPrice: "05.00 L" }
  ];

  const playersWithIcon = new Set([
    "Gonzalo Peillat",
    "Matthew Dawson",
    "Terrance Pieters",
    "Timothy Daniel",
    "Zachary Wallace",
    "Arthur De Sloover",
    "Jean Paul Danneberg",
    "Maico Casella"
  ]);

  return (
    <section className='text-black py-4 mx-auto md:px-[10rem]'>
      <div className='mx-auto m-2 gap-4 border-t border-gray-300 pb-5 rounded-lg'>
        <h3 className='uppercase bg-[#3B0067] rounded-t-lg px-4 py-2 text-white'>Players Bought</h3>
        <div className='overflow-x-auto'>
          <table className='min-w-full border-collapse border border-gray-300'>
            <thead>
              <tr className='bg-gray-200'>
                <th className='border border-gray-300 py-2 text-left px-4'>Players</th>
                <th className='border border-gray-300 py-2 text-center px-4'>Base Price (₹)</th>
                <th className='border border-gray-300 py-2 text-center px-4'>Auction Price (₹)</th>
              </tr>
            </thead>
            <tbody>
              {playersData.map((player, index) => (
                <tr key={index} className='border-b border-gray-300'>
                  <td className='py-2 px-2'>
                    <span className='flex md:gap-3 gap-1 md:font-bold '>{player.name}
                      {playersWithIcon.has(player.name) && <GiCommercialAirplane />}
                    </span>
                  </td>
                  <td className='py-2 px-4 text-center font-semibold md:font-bold'>{player.basePrice}</td>
                  <td className='py-2 px-4 text-center font-semibold md:font-bold'>{player.auctionPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default PlayersPrice;